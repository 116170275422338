import React from "react"
import { Link } from "gatsby"
import logo from "../../images/logo.png"

const footer = props => {
  return (
    <div>
      <div class="footer section">
        <div class="container">
          <div class="row mb-5">
            <div class="col-md-12">
              <h1 class="text-center text-purple">
                <strong>SUBSCRIBE TO OUR NEWSLETTER</strong>
              </h1>
              <p class="text-center text-black-50 section-paragraph">
                Get the latest Heavenly- Candy news delivered to your inbox,
                from new products to sweet discounts.
              </p>
            </div>
            <div class="col-md-8 mx-auto">
              <form method="post">
                <div class="row">
                  <div class="col-md-9">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="email"
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <button class="button-form" type="submit">
                        <strong>SIGN&nbsp;UP</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-sm-4">
              <img src={logo} />
              <p class="mt-2 text-purple small-p mb-5">
                We infuse our edibles with a 100% hemp-based, proprietary blend
                oil. Our oil is extracted using subcritical (low temperature and
                pressure) CO2. This method is non-toxic and energy efficient. We
                take pride in knowing our products are the best tasting and made
                using the best sources and methods.
              </p>
            </div>
            <div class="col-md-3 col-sm-4">
              <h6 class="text-purple mb-3">
                <strong>OUR SERVICES</strong>
              </h6>
              <ul class="footer-list text-purple ml-0">
                <li>
                  <a href="#">Order History</a>
                </li>
                <li>
                  <a href="#">Returns</a>
                </li>
                <li>
                  <a href="#">Specials</a>
                </li>
                <li>
                  <a href="#">My Account</a>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div class="col-md-3 col-sm-4 mb-3">
              <h6 class="text-purple mb-3">
                <strong>INFORMATION</strong>
              </h6>
              <ul class="footer-list text-purple ml-0">
                <li>
                  <a href="#">Products Order</a>
                </li>
                <li class="mb-3">
                  <Link to="/refunds-returns">Refunds &amp; Returns</Link>
                </li>
              </ul>
              <h6 class="text-purple mb-3 mt-4">
                <strong>MY ACCOUNT</strong>
              </h6>
              <ul class="footer-list text-purple ml-0">
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-conditions">Terms &amp; Conditions</Link>
                </li>
              </ul>
            </div>
            <div class="col-md-3 col-sm-4">
              <h6 class="text-purple mb-3">
                <strong>CONTACT</strong>&nbsp;<strong>US</strong>
              </h6>
              <div class="container">
                <a class="mb-1 row" href="#">
                  <i class="fa fa-map-marker col-md-1 pl-0 text-darkgold" />
                  &nbsp;
                  <br />
                  <p class="col text-left text-purple footer-info">
                    6525 Gunpark Dr #370-233 Boulder Co 80301
                    <br />
                  </p>
                </a>
                <a class="mb-1 row" href="#">
                  <i class="fa fa-phone col-md-1 pl-1 text-darkgold" />
                  &nbsp;
                  <br />
                  <p class="col text-left text-purple footer-info">
                    303.223.1665
                    <br />
                  </p>
                </a>
                <a class="mb-1 row" href="#">
                  <i class="fa fa-phone-square col-md-1 pl-1 text-darkgold" />
                  &nbsp;
                  <br />
                  <p class="col text-left text-purple footer-info">
                    +1 (603) 568- 2860 (Free messaging and calling)
                    <br />
                  </p>
                </a>
                <a class="mb-1 row" href="#">
                  <i class="fa fa-envelope col-md-1 pl-1 text-darkgold" />
                  &nbsp;
                  <br />
                  <p class="col text-left text-purple footer-info">
                    sales@heavenly-candy.com
                    <br />
                  </p>
                </a>
              </div>
              <div class="item social social-icons text-left">
                <a
                  href="https://www.facebook.com/heavenlycandycbd/"
                  target="_blank"
                  rel="no referrer"
                >
                  <i class="icon fa fa-facebook text-purple-50" />
                </a>
                <a
                  href="https://www.instagram.com/heavenlycandycbd/?hl=en"
                  target="_blank"
                  rel="no referrer"
                >
                  <i class="icon fa fa-instagram text-purple-50" />
                </a>
              </div>
            </div>
            <div class="col-lg-12 text-center mx-auto mt-5 text-purple">
              <span>
                © 2020 All rights reserved. Designed and powered by {``}
                <a
                  href="https://webriq.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WebriQ
                </a>
                .
              </span>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="copyright">
        <div className="container">
          <span>
            © 2019 All rights reserved. Designed and powered by {``}
            <a
              href="https://webriq.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              WebriQ
            </a>
            .
          </span>
        </div>
      </div>*/}
    </div>
  )
}

export default footer
