import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import styl from "./header.module.css"
import logo from "../../images/logo.png"

const Header = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: "unset", zIndex: "2" }}
    >
      <div className={`header ${styl.header}`}>
        <div className="upper-header">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <p className="text-center text-white small-p">
                  We infuse our edibles with a 100% hemp-based, proprietary
                  blend oil.
                </p>
                <p className="text-center text-white small-p" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-4">
              <div className="header-logo">
                <Link to="/">
                  <img className="img-fluid pt-2 mb-1" src={logo} />
                </Link>
              </div>
            </div>
            <div className="col-8 mt-2">
              <div className="text-right">
                <SideBarMenu />
              </div>
              <div className="text-center float-right mr-4 mb-3 mt-3 mt-md-1">
                <Link className="btn-alt mobile-cta" to="/cart">
                  <i className="fa fa-shopping-cart fa-cart-header mt-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
