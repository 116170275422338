import React, { useContext } from "react"
import { CartContext } from "../../context/CartContext"
import CurrencyFormatter from "../bigcommerce/CurrencyFormatter"
import { Link } from "gatsby"

const CartWrapper = ({ cartType }) => {
  const value = useContext(CartContext)
  if (!value) return null

  const { cart, cartLoading, updatingItem } = value.state
  return (
    <div>
      {!cartType && <Header />}
      {cartLoading ? (
        <div className="text-center py-3">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : cart.numberItems === 0 ? (
        <div className="text-center py-3">
          <h4>Your cart is empty.</h4>
          <Link to="/shop" className="text-primary">
            Take a look around
          </Link>
        </div>
      ) : (
            <Items
              items={cart.lineItems.physical_items || []}
              currency={cart.currency.code}
              updatingItem={updatingItem}
              updateCartItemQuantity={value.updateCartItemQuantity}
              removeItemFromCart={value.removeItemFromCart}
            />
          )}
      {cart.cartAmount > 0 && (
        <Footer
          currency={cart.currency.code}
          amount={cart.cartAmount}
          cartType={cartType}
        />
      )}
    </div>
  )
}

export default CartWrapper

const Items = ({
  items,
  currency,
  updatingItem,
  updateCartItemQuantity,
  removeItemFromCart,
}) => {
  return (
    <div>
      {items.map(item => (
        <div key={item.id} className="row py-3 border-bottom">
          <div className="col-md-3 text-center">
            <img
              className="img-fluid mb-0"
              src={item.image_url}
              alt={item.id}
            />
            <button
              className="btn btn-dark btn-block mb-3"
              onClick={() => removeItemFromCart(item.id)}
            >
              remove
            </button>
          </div>
          <div className="col-md-5">
            <p className="m-0">
              <small className="d-md-none text-muted mr-3">ITEM:</small>
              {item.name}
            </p>
          </div>
          <div className="col-md-2">
            <QtyBtn
              updatingItem={updatingItem}
              item={item}
              updateCartItemQuantity={updateCartItemQuantity}
            />
          </div>
          <div className="col-md-2">
            <p className="m-0">
              <small className="d-md-none text-muted mr-3">PRICE:</small>
              <CurrencyFormatter
                currency={currency}
                amount={item.extended_list_price}
              />
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

const Header = () => {
  return (
    <div className="d-none d-md-block">
      <div className="row border-bottom">
        <div className="col-md-8">
          <h6>ITEM</h6>
        </div>
        <div className="col-md-2">
          <h6>QTY</h6>
        </div>
        <div className="col-md-2">
          <h6>PRICE</h6>
        </div>
      </div>
    </div>
  )
}

const Footer = ({ currency, amount, cartType }) => {
  return (
    <div className="text-right py-3">
      <h4>
        <small className="text-muted mr-3">Subtotal:</small>
        <CurrencyFormatter currency={currency} amount={amount} />
      </h4>
      {!cartType && (
        <div>
          <Link className="btn btn-outline-dark" to="/shop">
            continue shopping
          </Link>
          <Link className="btn btn-dark mt-2 mt-md-0 ml-3" to="/checkout">
            proceed to checkout
          </Link>
        </div>
      )}
    </div>
  )
}

const QtyBtn = ({ item, updatingItem, updateCartItemQuantity }) => {
  if (updatingItem === item.id) {
    return <p className="text-center">...</p>
  }

  return (
    <div className="d-flex align-items-center">
      <small className="d-md-none text-muted mr-3">QTY:</small>
      <button
        className="btn btn-light"
        onClick={() => updateCartItemQuantity(item)}
      >
        +
      </button>
      <h6 className="m-0 px-2">{item.quantity}</h6>
      <button
        className="btn btn-light"
        onClick={() => updateCartItemQuantity(item, "minus")}
      >
        -
      </button>
    </div>
  )
}
