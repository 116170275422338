import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"
import logo from "../../images/logo.png"

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <div class="nav-brand-side mb-2 text-left">
        <img class="img-fluid" src={logo} />
      </div>
      <Nav.Item>
        <Link to="/">HOME</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/shop">SHOP</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/about">ABOUT US</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/blog">BLOG</Link>
      </Nav.Item>

      <Nav.Item>
        <Link to="/faq">FAQ</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/lab-reports">LAB REPORTS</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/about#wholesale">WHOLESALE</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/contact">CONTACT</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/about#start-your-business">START YOUR OWN BUSINESS</Link>
      </Nav.Item>
    </Nav>
  </div>
)
